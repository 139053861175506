export const arabic = {
  topBar: {
    follow: 'تابعونا:',
  },
  nav: {
    home: 'الصفحة الرئيسية',
    about: 'من نحن',
    products: 'الماكينات',
    contact: 'تواصل معنا',
    MachineDescription: 'وصف الماكينة',
    machineImages: 'صور الماكينة',
    MachineVideo: 'فيديوهات الماكينة',
    ViewAllProducts: 'جميع الماكينات',
    ExploreOurProducts: 'استكشف منتجاتنا',
  },
  slider: {
    slider1:
      'مشاركة قيمة بين العميل والشركة للحفاظ على تشغيل المنتج او الخدمة بسلاسه',
    slider2: 'أحدث التقنيات العالمية',
    slider3: 'احترافية فى تقديم الخدمات',
    slider4:
      'الخيار المثالي في عالم تصنيع ماكينات التغليف و انتاج خطوط السكاكر',
  },
  popularPlaces: {
    riyadh: 'الرياض',
    riyadhDesc:
      'منطقة الرياض، هي إحدى مناطق المملكة العربية السعودية، وتقع في المركز الجغرافي للبلد. وهي ثاني أكبر منطقة من حيث المساحة وعدد السكان، بعد المنطقة الشرقية ومنطقة مكة المكرمة على التوالي.',
    jeddah: 'جدة',
    firstmachinename: 'ماكينة سكاكر',
    secondmachinename: 'ماكينة نصف آلي',
    thirdmachinename: 'ماكينة أربع حبات',
    fourthmachinename: 'ماكينة التجميع',
    fifthmachinename: 'ماكينة الرول',
    sixmachinename: 'ماكينة تجميع الصفوف والاعمدة',
    sevenmachinename: 'ماكينة تلقيم آلي',
    firstmachinedesc:
      'تغليف كافة المنتجات على شكل كرة (البونبون - العلكة - الخ ) تحتوي الماكينة على اربعة سيرفو موتور',
    secondmachinedesc:
      'ماكينة نصف الي تغليف كافة المنتجات ذات الشكل الاسطواني او المستطيلة او المربعة تحتوي الماكينة على اربعة سيرفو موتور رئسية واثنين من السيور لتلقيم المنتج',
    thirdmachinedesc:
      'تغليف كافة المنتجات على شكل كرة (البونبون - العلكة - الخ ) ولاكن تتيح لنا التتنوع في الوان المنتج حسب احتياج العميل تحتوي الماكينة على اربعة سيرفو موتور رئيسية مع امكانية زيادتها حسب عدد الوان المنتج واحتياج العميل',
    fourthmachinedesc:
      'تغليف عدد معين من اصابع مستطيلة مع تجميعها في صف واحد حسب رغبة العميل تحتوي الماكينة على خمسة سيرفو موتور رئسية واثنين من السيور لتلقيم المنتج',
    fifthmachinedesc:
      'تغليف اصابع اسطوانية قطعة او قطعتين حسب اختيار العميل تحتوي الماكينة على خمسة سيرفو موتور',
    sixmachinedesc:
      'تغليف على هيئة اكثر من عمود وكل عمود يحتوي على عدد من القطع حسب احتياج العميل تحتوي الماكينة على اربعة سيرفو موتور',
    sevenmachinedesc:
      'تغليف كافة المنتجات ذات الشكل الاسطواني او المربعة ولاكن دون تدخل بشري تحتوي الماكينة على اربعة سيرفو موتور رئسية وتزيد حسب الطريقة الالية لخطوط الانتاج لدى العميل',
    jeddahDesc:
      'جدة هي مدينة في منطقة الحجاز بالمملكة العربية السعودية والمركز التجاري للبلاد. تأسست جدة في القرن السادس قبل الميلاد كقرية صيد، ونمت شهرة جدة في عام 647 عندما جعلها الخليفة عثمان ميناءًا رئيسيًا لطرق التجارة في المحيط الهندي، وتوجيه البضائع إلى مكة، وخدمة المسافرين المسلمين للحج الإسلامي.',
    madinah: 'المدينة المنورة',
    madinahDesc:
      'المدينة، رسمياً المدينة المنورة. إنها رابع أكبر مدينة في البلاد من حيث عدد السكان. تقع في قلب محافظة المدينة المنورة في الروافد الغربية للبلاد، بينما يحتل الباقي جبال الحجاز والوديان الفارغة والمساحات الزراعية والبراكين الخاملة الأقدم.',
    dammam: 'الدمام',
    dammamDesc:
      'الدمام هي خامس مدينة من حيث عدد السكان في المملكة العربية السعودية بعد الرياض وجدة ومكة والمدينة المنورة. هي عاصمة المنطقة الشرقية. وتقع في المدينة الهيئات القضائية والإدارية للمحافظة، بالإضافة إلى المكاتب الإدارية للإدارات الحكومية الفرعية الأخرى العاملة داخل المحافظة.',
  },
  general: {
    firstService: 'انتاج خطوط السكاكر',
    firstServiceDescription: 'إنتاج خطوط السكاكر والتوفى والعلكة',
    secondService: 'ماكينات التغليف الأفقية',
    secondServiceDescription:
      'انتاج جميع ماكينات التغليف والملقمات اللازمة لكل المنتجات حسب رغبة العميل مع مراعات مقاس كل نوع من طول وعرض المنتج المطلوب',
    thirdService: 'خدمات ما بعد البيع',
    thirdServiceDescription: 'تشمل الصيانة وتوريد قطع الغيار',
    fourthService: 'تصنيع الماكينات مع خطوط التلقيم الألية',
    fourthServiceDescription:
      'تصنيع الماكينات مع خطوط التلقيم الألية. لتطوير صناعة الماكينات بما يتناسب مع أحدث التقنيات العالمية.',
    transport: 'المواصلات',
    catering: 'تقديم الطعام',
    pcr: 'PCR اختبار',
    hotelServices:
      'تجديد وتجهيز مرافق اقامة السائحين المرخصة من وزارة السياحة والمؤهلة للحجر الصحي على جميع المستويات خمس نجوم واربعة نجوم وثلاث نجوم',
    transportServices:
      'توفير أحدث وسائل النقل من المطار إلى المأوى ، وفق الاشتراطات الاحترازية التي تحددها هيئة الصحة العامة بحضور المشرفين الميدانيين.',
    cateringServices:
      'تقديم خدمات التموين وفق المتطلبات والمعايير الصحية مع مراعاة القائمة الخاصة بكل ضيف',
    pcrServices:
      'إجراء تحاليل تفاعل البوليميراز المتسلسل من مختبرات معتمدة ومرخصة من وزارة الصحة السعودية',
    nights: 'ليالي',
    meals: 'وجبات',
    oneWay: 'اتجاه مباشر',
    fromAirport: 'من المطار',
    visas: 'تأشيرات سياحية',
    hotelReservation: 'حجوزات فندقية بجميع المستويات',
    transportations: 'وسيلة النقل الخاصة بالنقل الداخلي',
    hotelCatering: 'خدمات المطاعم للفنادق',
    institutionalQuarantine: 'خدمات الحجر الصحي المؤسسي',
    copyright: 'حقوق نشر',
    companyName: 'العالمية',
    year: '@٢٠٢٤',
    a: '٠',
    b: '١',
    c: '٢',
    d: '٣',
    e: '٤',
    f: '٥',
    g: '٦',
    h: '٧',
    i: '٨',
    j: '٩',
  },
  pageHeaders: {
    ourProducts: 'الماكينات',
    quarantine: 'خدمات الحجر الصحي',
    ourHotels: 'فنادقنا',
    allotmentHotels: 'فنادق مخصصة',
    ourPartners: 'عملائنا',
    welcome: 'مرحباً بكم في العالمية',
    overview: 'نظرة عامة',
    ourServices: 'خدماتنا',
    ourCompany: 'شركتنا',
    ourVision: 'رؤيتنا',
    contactUs: 'تواصل معنا',
    leadership: 'الرئاسة',
    ourCities: 'مدننا',
    navigation: 'التنقل',
  },
  hotels: {
    taj: 'التاج الراقي',
    subTaj: 'تاج الخليل سابقاً',
    hijra: 'الهجرة الراقي',
    subHijra: 'أفواج التوبة ١١ سابقاً',
    safwa: 'الصفوة الراقي',
    subSafwa: 'صفوة التاج سابقا',
    malak: 'ملاك الراقي',
    subMalak: 'ملاك التقوى سابقا',
    kady: 'كادي الراقي',
    subKady: 'زهور التاج سابقا',
    azka: 'ازكى الصفا',
    nesour: 'نسور المهاجرين',
    mathaer: 'مئاثر الجوار',
    fajr: 'فجر البديع ٢',
    royal: 'رويال ماجيستيك',
    bolman: 'بولمان زمزم',
  },
  about: {
    companyDescTitle2: 'رؤية الشركة',
    companyDescTitle3: 'الرسالة',
    companyDesc:
      ' تأسست الشركة عام 1997 في مدينة حمص-سوريا وقد تخصصت الشركة في بداية عملها في انتاج خطوط السكاكر(بونبون) على مدار 5 سنوات و في عام 2002 بدأت الشركة بانتاج ماكينات التغليف الأفقية لتغطي كافة الاستخدامات.تابعت تطوير الماكينة معتمدة على استخدام محركات السيرفو موتور مستغنية عن الأنظمة الميكانيكية القديمة و ذلك لضعف أدائها و بطئ حركتها. و في عام 2012 تابعت الشركة عملها في جمهورية مصر العربية-مدينة العاشر من رمضان وبدأت بتصنيع الماكينات مع خطوط التلقيم الألية. و تسعى الشركة دائما لتطوير صناعة الماكينات بما يتناسب مع أحدث التقنيات العالمية.',
    companyDesc2:
      'نسعى دوما أن يكون اسم “الشركة العالمية” اسما رائدا و خياراً مثاليا في عالم تصنيع ماكينات التغليف في سوريا و مصر من خلال تقديم خدماتنا باحترافية عالية.',
    companyDesc3:
      'توجيه الاهتمام نحو تنفيذ الخدمات الى العملاء بأعلى معايير الجودة و بأعلى المستويات وفق ما رصدناه من ميزانية مع مراعاة عنصر الوقت المحدد مسبقا.',
    visionDesc:
      'خدمة الحجاج من جميع أنحاء العالم وارتباطها برؤية قيادتنا الرشيدة. طاقاتنا وقدراتنا لخدمتهم ، وأولويتنا عدم ادخار أي جهد في بذل كل جهد. تقديم كل ما يلبي احتياجات ضيوف الله ويحقق تطلعاتهم.',
  },
  contact: {
    callUs: 'اتصل بنا الآن',
    support: 'تواصل معنا عبر البريد الإلكتروني',
    phoneNumber: '٠١٥٥٥٥٣٠٣٠٦',
    phoneNumber2: '٠١٢٧٧٤٢٦٨٩١',
    address: 'مدينة العاشر من رمضان',
    subAddress: 'عنواننا',
    availability: 'قم بزيارتنا الأن',
    mailUs: 'راسلنا بالبريد الآن',
    submit: 'إرسال',
    YourEmail: 'بريدك الشخصي',
    Phone: 'هاتفك',
    Name: 'الإسم',
    Subject: 'الموضوع',
    Message: 'الرسالة',
  },
  leaders: {
    director: 'أحمد خواجة',
    directorPos: 'المدير التنفيذي',
  },
};
