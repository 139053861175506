import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';

const FormContact = () => {
  const lang = useContext(LanguageContext);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('send_email.php', {
      method: 'POST',
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert('Email sent successfully!');
          setFormData({
            name: '',
            phone: '',
            email: '',
            subject: '',
            message: '',
          });
        } else {
          alert('Failed to send email. Please try again later.');
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className="col-md-12">
      <div
        className="themesflat-spacer clearfix"
        data-desktop={36}
        data-mobile={35}
        data-smobile={35}
      />
      <div className="themesflat-contact-form style-2 clearfix">
        <form
          onSubmit={handleSubmit}
          action="#"
          method="post"
          className="form-submit contact-form wpcf7-form"
        >
          <span className="wpcf7-form-control-wrap your-name">
            <input
              type="text"
              tabIndex={1}
              id="name"
              name="name"
              onChange={handleChange}
              defaultValue={formData.name}
              className="wpcf7-form-control"
              placeholder={lang.lang.contact.Name}
              required
            />
          </span>
          <span className="wpcf7-form-control-wrap your-phone">
            <input
              type="text"
              tabIndex={2}
              id="phone"
              name="phone"
              onChange={handleChange}
              defaultValue={formData.phone}
              className="wpcf7-form-control"
              placeholder={lang.lang.contact.Phone}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-email">
            <input
              type="email"
              tabIndex={3}
              id="email"
              name="email"
              onChange={handleChange}
              defaultValue={formData.email}
              className="wpcf7-form-control"
              placeholder={lang.lang.contact.YourEmail}
              required
            />
          </span>
          <span className="wpcf7-form-control-wrap your-subject">
            <input
              type="text"
              tabIndex={4}
              id="subject"
              name="subject"
              onChange={handleChange}
              defaultValue={formData.subject}
              className="wpcf7-form-control"
              placeholder={lang.lang.contact.Subject}
            />
          </span>
          <span className="wpcf7-form-control-wrap your-message">
            <textarea
              name="message"
              tabIndex={5}
              cols={40}
              rows={10}
              className="wpcf7-form-control wpcf7-textarea"
              placeholder={lang.lang.contact.Message}
              required
              defaultValue={formData.message}
              onChange={handleChange}
              // value={formData.message}
            />
          </span>
          {/* <button className="elm-button text-center p-5" type="submit"> */}
          <div
            className="elm-button text-center p-5"
            style={{ display: 'flex', justifyContent: 'center' }}
            type="submit"
          >
            <button className="themesflat-button bg-accent pd30">
              {lang.lang.contact.submit}
            </button>
          </div>
          {/* </button> */}
          {/* <span className="wrap-submit">
              <input
                type="submit"
                defaultValue="SEND US"
                className="submit wpcf7-form-control wpcf7-submit text-capitalize"
                id="submit"
                name="submit"
              />
            </span> */}
        </form>
      </div>
      <div
        className="themesflat-spacer clearfix"
        data-desktop={80}
        data-mobile={60}
        data-smobile={60}
      />
    </div>
  );
};

export default FormContact;
