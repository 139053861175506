import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { LanguageConsumer, LanguageContext } from '../../LanguageContext';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import HotelCard from '../layouts/general/HotelCard';
import { Footer, BottomBar, TopBar, Header } from '../layouts/general/index';
import styles from './styles.css';
import { SliderPopularPlaces, TitleHeading } from '../layouts/home';

const AllProducts = () => {
  const activeUrl = useLocation();
  const lang = useContext(LanguageContext);

  let state = {
    headers: [
      {
        id: 1,
        logoweb: 'assets/img/logo-small.png',
        names: lang.lang.nav.products,
      },
    ],
    banners: [
      {
        id: 1,
        links: '/hotels',
        titlelink: '',
        border: '',
        name: lang.lang.nav.products,
        heading: lang.lang.nav.products,
      },
    ],
    headingcontact: [
      {
        id: 1,
        classnames: 'heading',
        title: lang.lang.pageHeaders.ourProducts,
        // title: 'Our Products',
      },
    ],
  };
  let content = {
    headers: [
      {
        id: 1,
        logoweb: 'assets/img/logo-white-small.png',
        names: lang.lang.nav.home,
      },
    ],
    ourProducts: [
      {
        id: 1,
        classnames: 'heading',
        title: lang.lang.pageHeaders.ourProducts,
      },
    ],
    contact: [
      {
        id: 1,
        classnames: 'heading text',
        title: lang.lang.pageHeaders.contactUs,
      },
    ],
  };
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  return (
    <LanguageConsumer>
      {({ language }) => (
        <div
          className="header-fixed page no-sidebar header-style-3 topbar-style-3 site-header-absolute menu-has-search"
          id="products"
        >
          {/* <div id="wrapper" className="animsition"> */}
          <div id="page" className="clearfix">
            {/* <div id="site-header-wrap"> */}
            <div>
              {state.headers.map((data, idx) => (
                <Header type={'product'} data={data} key={data.id} products />
              ))}
            </div>
            <div
              style={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
              }}
            >
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex:',
                  alignSelf: 'center',
                }}
                src="assets/img/slider/newmain.jpg"
                alt="altimage"
                data-bgposition="center center"
                data-no-retina
              />
              <div
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  position: 'absolute',
                  width: '100%',
                  height: '500px',
                  zIndex: 1,
                }}
              ></div>
              <div
                className={
                  'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center'
                }
                style={{ fontSize: 50, lineHeight: 'initial' }}
                // data-x={['left', 'left', 'left', 'center']}
                // data-hoffset={"['34','34','34','0']"}
                // data-y="['middle','middle','middle','middle']"
                // data-voffset={"['-63','-63','-63','-63']"}
                // data-fontsize="['52','52','42','32']"
                // data-lineheight="['65','65','45','35']"
                // data-width="full"
                // data-height="none"
                // data-whitespace="normal"
                // data-transform_idle="o:1;"
                // data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                // data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                // data-mask_in="x:0px;y:[100%];"
                // data-mask_out="x:inherit;y:inherit;"
                // data-start={500}
                // data-splitin="none"
                // data-splitout="none"
                // data-responsive_offset="on"
              >
                {lang.lang.nav.ExploreOurProducts}
              </div>
            </div>
            <div
              className="themesflat-headings style-1 clearfix"
              style={{ marginTop: '5%' }}
            >
              {state.headingcontact.map((data) => (
                <TitleHeading data={data} key={data.id} />
              ))}
            </div>
            <div>
              <SliderPopularPlaces type={'AllProducts'} />
            </div>
            {/* </div> */}
            <div style={{ marginTop: '5%' }}>
              {content.headers.map((data, idx) => (
                <Footer data={data} key={idx} />
              ))}
              {content.headers.map((data, idx) => (
                <BottomBar data={data} key={idx} lang={lang} />
              ))}
            </div>
          </div>
        </div>
      )}
    </LanguageConsumer>
  );
};

export default AllProducts;
