import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';
import { Grid } from '@mui/material';

const SliderPopularPlaces = (props) => {
  const lang = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  let state = {
    slider: [
      {
        id: 1,
        srcimg: 'assets/img/imagebox/firstmachine.png',
        heading: lang.lang.popularPlaces.riyadh,
        text: lang.lang.popularPlaces.riyadhDesc,
        headerName: lang.lang.popularPlaces.firstmachinename,
        machinedesc: lang.lang.popularPlaces.firstmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/ZvPQg6wKqxM',
      },
      {
        id: 2,
        srcimg: 'assets/img/imagebox/machine3.jpg',
        heading: lang.lang.popularPlaces.jeddah,
        text: lang.lang.popularPlaces.jeddahDesc,
        headerName: lang.lang.popularPlaces.secondmachinename,
        machinedesc: lang.lang.popularPlaces.secondmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/SQq1FeAJI5M',
      },
      {
        id: 3,
        srcimg: 'assets/img/imagebox/m6a.png',
        heading: lang.lang.popularPlaces.madinah,
        text: lang.lang.popularPlaces.madinahDesc,
        headerName: lang.lang.popularPlaces.thirdmachinename,
        machinedesc: lang.lang.popularPlaces.thirdmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/mg3FpkU4UzY',
      },
      {
        id: 4,
        srcimg: 'assets/img/imagebox/m5a.png',
        heading: lang.lang.popularPlaces.dammam,
        text: lang.lang.popularPlaces.dammamDesc,
        headerName: lang.lang.popularPlaces.fourthmachinename,
        machinedesc: lang.lang.popularPlaces.fourthmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/KpVUb5CfDCM',
      },
      {
        id: 5,
        srcimg: 'assets/img/imagebox/m7a.png',
        heading: lang.lang.popularPlaces.dammam,
        text: lang.lang.popularPlaces.dammamDesc,
        headerName: lang.lang.popularPlaces.fifthmachinename,
        machinedesc: lang.lang.popularPlaces.fifthmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/0p4UtatxGN0',
      },
      {
        id: 6,
        srcimg: 'assets/img/imagebox/m3a.png',
        heading: lang.lang.popularPlaces.dammam,
        text: lang.lang.popularPlaces.dammamDesc,
        headerName: lang.lang.popularPlaces.sixmachinename,
        machinedesc: lang.lang.popularPlaces.sixmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/dgDr4zMKWP0',
      },
      {
        id: 7,
        srcimg: 'assets/img/imagebox/el3alamya.330.jpg',
        heading: lang.lang.popularPlaces.dammam,
        text: lang.lang.popularPlaces.dammamDesc,
        headerName: lang.lang.popularPlaces.sevenmachinename,
        machinedesc: lang.lang.popularPlaces.sevenmachinedesc,
        srcVideo: 'https://www.youtube.com/embed/y9veZEAw5xk',
      },
    ],
  };
  let productsToShow = state.slider;
  if (props.type !== 'AllProducts') {
    productsToShow = state.slider.filter((product) => product.id !== 7);
  }
  return (
    <Grid container justifyContent="center">
      {productsToShow.map((data) => (
        <div
          className=" themesflat-image-box style-2 clearfix"
          style={{
            direction: 'rtl',
            display: 'flex',
            flexDirection: 'row',
            margin: 10,
          }}
          key={data.id}
        >
          <div
            onClick={() => {
              const queryString = encodeURIComponent(JSON.stringify(data));
              window.location.href = `/Products?data=${queryString}`;
            }}
            style={{
              borderRadius: 15,
              cursor: 'pointer',
              width: isMobile ? '250px' : '450px',
              height: isMobile ? '170px' : '370px',
            }}
            className="content"
          >
            <div className="content-overlay"></div>
            <div
              style={{
                padding: '10px',
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                position: 'absolute',
                width: '100%',
                height: '100%',
                marginLeft: 20,
              }}
            ></div>
            <img
              // className="content-image"
              src={data.srcimg}
              alt=""
              resize="cover"
              style={{ width: '100%', height: '100%' }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                alignSelf: 'center',
                flexDirection: 'column',
              }}
              className="content-details fadeIn-bottom fadeIn-left"
            >
              <h3>{data.headerName}</h3>
              {/* {props.type === 'AllProducts' && ( */}
              <div
                onClick={() => {
                  const queryString = encodeURIComponent(JSON.stringify(data));
                  window.location.href = `/Products?data=${queryString}`;
                }}
                className="View-button"
              >
                View
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      ))}
    </Grid>
    // <div
    //   className="row col-lg-12"
    //   style={{ backgroundColor: 'red' }}
    //   // className="themesflat-carousel-box has-bullets bullet-circle arrow-center offset-v-111 offset-h-21 arrow-circle arrow-style-2 data-effect clearfix"
    //   // data-gap={75}
    //   // data-column={3}
    //   // data-column2={2}
    //   // data-column3={1}
    //   // data-auto="true"
    // >
    //   <div
    //     // style={{ display: 'flex', flexDirection: 'row' }}
    //     className="col-lg-4"
    //   >
    // {state.slider.map((data) => (
    //   <div
    //     className=" themesflat-image-box style-2 clearfix"
    //     style={{ direction: 'rtl', display: 'flex', flexDirection: 'row' }}
    //     key={data.id}
    //   >
    //     <div
    //       style={{
    //         // width: '100%',
    //         borderRadius: 15,
    //         // height: '100%',
    //         cursor: 'pointer',
    //         width: '380px',
    //         height: '350px',
    //       }}
    //       className="content"
    //     >
    //       <div className="content-overlay"></div>
    //       <div
    //         style={{
    //           padding: '10px',
    //           backgroundColor: 'rgba(0, 0, 0, 0.6)',
    //           position: 'absolute',
    //           width: '100%',
    //           height: '100%',
    //           marginLeft: 20,
    //         }}
    //       ></div>
    //       <img className="content-image" src={data.srcimg} alt="" />
    //       <div
    //         style={{
    //           display: 'flex',
    //           alignItems: 'center',
    //           alignSelf: 'center',
    //           flexDirection: 'column',
    //         }}
    //         className="content-details fadeIn-bottom fadeIn-left"
    //       >
    //         <h3>Machine Name</h3>
    //         {/* <div className="View-button">View</div> */}
    //       </div>
    //     </div>
    //     {/* <div className="image-box-item">
    //       <div className="inner">
    //         <div className="thumb data-effect-item">
    //           <img src={data.srcimg} alt="altimage" />
    //           <div className="overlay-effect bg-color-accent" />
    //         </div>
    //         <div className="text-wrap">
    //           <h5 className="heading">
    //             <Link to={data?.id}>{data.heading}</Link>
    //           </h5>
    //           <p>{data.text}</p>
    //         </div>
    //       </div>
    //     </div> */}
    //   </div>
    // ))}
    //   </div>
    // </div>
  );
};
export default SliderPopularPlaces;
