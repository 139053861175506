import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ConstructionIcon from '@mui/icons-material/Construction';
import FactoryIcon from '@mui/icons-material/Factory';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { LanguageConsumer, LanguageContext } from '../../../LanguageContext';

const IconBox = () => {
  const lang = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });
  let state = {
    iconbox: [
      {
        id: 1,
        icon: <ConstructionIcon fontSize="14px" />,
        title: lang.lang.general.firstService,
        text: lang.lang.general.firstServiceDescription,
      },
      {
        id: 2,
        icon: <FactoryIcon fontSize="14px" />,
        title: lang.lang.general.secondService,
        text: lang.lang.general.secondServiceDescription,
      },
      {
        id: 3,
        icon: <PrecisionManufacturingIcon fontSize="14px" />,
        title: lang.lang.general.thirdService,
        text: lang.lang.general.thirdServiceDescription,
      },
      // {
      //   id: 4,
      //   icon: <VaccinesIcon fontSize="14px" />,
      //   title: lang.lang.general.fourthService,
      //   text: lang.lang.general.fourthServiceDescription,
      // },
    ],
  };
  return (
    <LanguageConsumer>
      {({ language }) => (
        <div
          className="themesflat-row gutter-30 gutter-mobile clearfix"
          style={{
            direction: language === 'english' ? 'ltr' : 'rtl',
            // display: isMobile ? 'block' : 'flex',
          }}
        >
          {state.iconbox.map((data) => (
            <div
              style={{ marginBottom: 10 }}
              className="col span_1_of_4"
              key={data.id}
            >
              <div
                className="themesflat-spacer clearfix"
                data-desktop={0}
                data-mobile={0}
                data-smobile={35}
              />
              <div
                style={{ height: '450px', borderRadius: 15 }}
                className="themesflat-icon-box icon-top align-center has-width w95 circle light-bg accent-color style-1 bg-white-column padding-inner clearfix"
              >
                <div className="icon-wrap pt-2">{data.icon}</div>
                <div className="text-wrap">
                  <h5 className="heading" style={{ fontSize: '25px' }}>
                    <Link to="#">{data.title}</Link>
                  </h5>
                  <div className="sep clearfix" />
                  <p className="sub-heading" style={{ fontSize: '20px' }}>
                    {data.text}
                  </p>
                </div>
              </div>
              <div className="divider h35" />
            </div>
          ))}
        </div>
      )}
    </LanguageConsumer>
  );
};

export default IconBox;
