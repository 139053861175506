import React, { cloneElement, useContext, useEffect, useState } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { LanguageContext, LanguageConsumer } from '../../../LanguageContext';
import { useScrollTrigger } from '@mui/material';
import PropTypes from 'prop-types';
import { type } from '@testing-library/user-event/dist/type';

const Header = (props) => {
  const location = useLocation();
  const lang = useContext(LanguageContext);

  const [activeSection, setActiveSection] = useState(null);

  let state = {
    menus: [
      {
        id: 1,
        name: lang.lang.nav.home,
        links: 'home',
      },
      {
        id: 2,
        name: lang.lang.nav.about,
        links: 'about-us',
      },
      {
        id: 3,
        name: lang.lang.nav.products,
        links: 'products',
      },
      {
        id: 4,
        name: lang.lang.pageHeaders.ourPartners,
        links: 'partners',
      },
      {
        id: 5,
        name: lang.lang.nav.contact,
        links: 'contact-us',
      },
      {
        id: 6,
        name: lang.language !== 'english' ? 'English' : 'العربية',
        links: 'top',
      },
    ],
  };

  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    useEffect(() => {
      const handleScroll = () => {
        const currentPosition = window.scrollY;
        const sections = [
          'home',
          'about-us',
          'products',
          'contact-us',
          'partners',
        ];
        let activeSection = null;

        sections.forEach((section) => {
          const element = document.getElementById(section);
          if (
            element &&
            element.offsetTop <= currentPosition &&
            element.offsetTop + element.offsetHeight > currentPosition
          ) {
            activeSection = section;
          }
        });

        setActiveSection(activeSection || 'top');
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [window]);

    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };
  return (
    <LanguageConsumer>
      {({ language, changeLanguage }) => (
        <header
          id="site-header"
          style={{ direction: language === 'english' ? 'ltr' : 'rtl' }}
        >
          <div
            style={{
              backgroundColor: props.type === 'product' ? '#54595F' : '',
              width: props.type === 'product' ? '100%' : '',
              padding: props.type === 'product' ? '0 10%' : '',
            }}
            id="site-header-inner"
            className="container"
          >
            <div
              className="mobile-button"
              style={{
                left: language === 'arabic' && 20,
                right: language === 'arabic' ? 'revert' : '',
              }}
            >
              <span />
            </div>
            <div className="wrap-inner clearfix">
              <img
                src="assets/img/Logo04@2x.png"
                alt="imagealt"
                width={200}
                height={34}
              />

              <nav
                id="main-nav"
                className="main-nav"
                style={{
                  left:
                    language === 'arabic' && props.type === 'product'
                      ? '10%'
                      : language === 'arabic' && '10px',
                  direction: language === 'english' ? 'ltr' : 'rtl',
                }}
              >
                <ul id="menu-primary-menu" className="menu">
                  {state.menus.map((data) => (
                    <li
                      className={
                        (data.links === location.pathname.substring(1) ||
                          data.links === activeSection) &&
                        data.id !== 6
                          ? 'menu-item menu-item-has-children current-menu-item'
                          : 'menu-item menu-item-has-children'
                      }
                      key={data.id}
                    >
                      <ScrollLink
                        style={{ cursor: 'pointer' }}
                        to={data.links}
                        spy
                        // smooth
                        // offset={-70}
                        // duration={500}
                        onClick={() => {
                          if (props?.products) {
                            window.location.href = '/';
                          }
                          if (data?.id === 6) {
                            changeLanguage();
                          }
                        }}
                      >
                        {data.name}
                      </ScrollLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      )}
    </LanguageConsumer>
  );
};

export default Header;
