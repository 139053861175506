import { useContext } from 'react';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import { LanguageContext } from '../../LanguageContext';
import { IconBox } from '../layouts/contact';
import { TitleHeading } from '../layouts/home/index';
import { Header, Footer, BottomBar } from '../layouts/general/index';

const ContactUs = ({}) => {
  const lang = useContext(LanguageContext);

  let state = {
    headers: [
      {
        id: 1,
        logoweb: 'assets/img/logo-small.png',
        names: lang.lang.nav.contact,
      },
    ],
    banners: [
      {
        id: 1,
        links: '',
        titlelink: '',
        border: '',
        name: lang.lang.nav.contact,
        heading: lang.lang.nav.contact,
      },
    ],
    headingcontact: [
      {
        id: 1,
        classnames: 'heading',
        title: lang.lang.nav.contact,
      },
    ],
  };
  return (
    <div
      className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search"
      style={{ direction: lang.language === 'english' ? 'ltr' : 'rtl' }}
    >
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <div className="row-iconbox">
                      <div className="container">
                        <IconBox />
                        {/* <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={58}
                              data-mobile={35}
                              data-smobile={35}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
