import { useContext } from 'react';
import { LeaderTeam } from '../layouts/about';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import { About } from '../layouts/home/index';
import { Header, Footer, BottomBar, TopBar } from '../layouts/general/index';
import { LanguageConsumer, LanguageContext } from '../../LanguageContext';

const AboutUs = () => {
  const lang = useContext(LanguageContext);

  return (
    <LanguageConsumer>
      {({ language }) => (
        <section
          id="about-us"
          className="header-fixed page no-sidebar header-style-2 topbar-style-1 menu-has-search"
          style={{
            direction: language === 'english' ? 'ltr' : 'rtl',
            paddingTop: '7%',
          }}
        >
          <div id="wrapper" className="animsition">
            <div id="page" className="clearfix">
              <div id="main-content" className="site-main clearfix">
                <div id="content-wrap">
                  <div id="site-content" className="site-content clearfix">
                    <div id="inner-content" className="inner-content-wrap">
                      <div className="page-content">
                        <About lang={lang} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </LanguageConsumer>
  );
};

export default AboutUs;
