import Home from './Home';
import Products from './Products';
import ContactUs from './ContactUs';
import AllProducts from './AllProducts';

const routes = [
  { path: '/', component: Home },
  { path: '/products', component: Products },
  { path: '/contact-us', component: ContactUs },
  { path: '/AllProducts', component: AllProducts },
];

export default routes;
