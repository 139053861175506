import React, { useContext } from 'react';
import { Overview, Slider, TitleHeading } from '../layouts/home/index';
import { IconBox, SliderPopularPlaces, Partner } from '../layouts/home/index';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';
import Hotels from '../layouts/home/Hotels';
import { LanguageContext, LanguageConsumer } from '../../LanguageContext';
import { LeaderTeam } from '../layouts/about';
import AboutUs from './AboutUs';
import { Link } from 'react-router-dom';
import ContactUs from './ContactUs';

const Home = () => {
  const lang = useContext(LanguageContext);
  let content = {
    headers: [
      {
        id: 1,
        logoweb: 'assets/img/logo-white-small.png',
        names: lang.lang.nav.home,
      },
    ],
    ourProducts: [
      {
        id: 1,
        classnames: 'heading',
        title: lang.lang.pageHeaders.ourProducts,
      },
    ],
    contact: [
      {
        id: 1,
        classnames: 'heading text',
        title: lang.lang.pageHeaders.contactUs,
      },
    ],
  };
  return (
    <div className="header-fixed page no-sidebar header-style-3 topbar-style-3 site-header-absolute menu-has-search">
      <div id="wrapper" className="animsition">
        <div id="page" className="clearfix">
          <div id="site-header-wrap">
            {content.headers.map((data, idx) => (
              <Header data={data} key={idx} />
            ))}
          </div>
          <div id="main-content" className="site-main clearfix">
            <div id="content-wrap">
              <div id="site-content" className="site-content clearfix">
                <div id="inner-content" className="inner-content-wrap">
                  <div className="page-content">
                    <section
                      className="rev_slider_wrapper fullwidthbanner-container"
                      id="home"
                    >
                      <div
                        id="rev-slider2"
                        className="rev_slider fullwidthabanner"
                      >
                        <Slider />
                      </div>
                    </section>
                    <AboutUs lang={lang} />

                    <div id="products">
                      <div className="container" style={{ width: '100%' }}>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={60}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            {content.ourProducts.map((data) => (
                              <TitleHeading data={data} key={data.id} />
                            ))}
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop="27"
                              data-mobile="35"
                              data-smobile="35"
                            ></div>
                            <SliderPopularPlaces />
                            <div
                              className="themesflat-spacer clearfix"
                              data-desktop={45}
                              data-mobile={60}
                              data-smobile={60}
                            />
                            <div className="elm-button text-center">
                              <Link
                                to=""
                                onClick={() => {
                                  window.location.href = '/AllProducts';
                                }}
                                className="themesflat-button bg-accent pd30"
                              >
                                {lang.lang.nav.ViewAllProducts}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row-project clearfix" id="partners">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-12 p-5"
                            style={{ marginTop: '5%' }}
                          >
                            <TitleHeading
                              data={{
                                title: lang.lang.pageHeaders.ourPartners,
                              }}
                            />

                            <Partner lang={lang} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className="row-iconbox bg-row-2" id="contact-us">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12 p-5">
                          <div
                            className="themesflat-spacer clearfix"
                            data-desktop={60}
                            data-mobile={60}
                            data-smobile={60}
                          />
                          {content.contact.map((data) => (
                            <TitleHeading data={data} key={data.id} />
                          ))}
                          <div
                            className="themesflat-spacer clearfix"
                            data-desktop={25}
                            data-mobile={35}
                            data-smobile={35}
                          />
                          <ContactUs />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          {content.headers.map((data, idx) => (
            <Footer data={data} key={idx} />
          ))}
          {content.headers.map((data, idx) => (
            <BottomBar data={data} key={idx} lang={lang} />
          ))}
        </div>
      </div>
    </div>
  );
};
export default Home;
