import { cloneElement, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';
import { Link as ScrollLink } from 'react-scroll';
import { useScrollTrigger } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

const Footer = (props) => {
  const location = useLocation();

  const lang = useContext(LanguageContext);
  const [activeSection, setActiveSection] = useState(null);

  let state = {
    menus: [
      {
        id: 1,
        name: lang.lang.nav.home,
        links: 'home',
      },
      {
        id: 2,
        name: lang.lang.nav.about,
        links: 'about-us',
      },
      {
        id: 3,
        name: lang.lang.nav.products,
        links: 'products',
      },
      {
        id: 4,
        name: lang.lang.nav.contact,
        links: 'contact-us',
      },
      {
        id: 5,
        name: lang.lang.nav.ourPartners,
        links: 'partners',
      },
    ],
  };
  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    useEffect(() => {
      const handleScroll = () => {
        const currentPosition = window.scrollY;
        const sections = [
          'home',
          'about-us',
          'products',
          'contact-us',
          'partners',
        ];
        let activeSection = null;

        sections.forEach((section) => {
          const element = document.getElementById(section);
          if (
            element &&
            element.offsetTop <= currentPosition &&
            element.offsetTop + element.offsetHeight > currentPosition
          ) {
            activeSection = section;
          }
        });

        setActiveSection(activeSection || 'top');
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [window]);

    return cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };
  return (
    <footer
      id="footer"
      className="clearfix"
      style={{ direction: lang.language === 'english' ? 'ltr' : 'rtl' }}
    >
      <div id="footer-widgets" className="container">
        <div className="themesflat-row gutter-30">
          <div className="col span_1_of_3">
            <div className="widget widget_text">
              <div className="textwidget">
                <img
                  src="assets/img/Logo04@2x.png"
                  alt="imagealt"
                  width={300}
                  height={34}
                />
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={80}
                  data-mobile={60}
                  data-smobile={60}
                />
              </div>
            </div>

            {/* /.widget_text */}
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={35}
            />
          </div>
          <div className="col span_1_of_3">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={0}
            />
            {/* <div className="widget widget_lastest">
              <h2 className="widget-title">
                <span>{lang.lang.pageHeaders.ourCities}</span>
              </h2>
              <ul className="lastest-posts data-effect clearfix">
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="text">
                    <h3>
                      <div>{lang.lang.popularPlaces.riyadh}</div>
                    </h3>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="text">
                    <h3>{lang.lang.popularPlaces.jeddah}</h3>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="text">
                    <h3>{lang.lang.popularPlaces.dammam}</h3>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="text">
                    <h3>
                      <div>{lang.lang.popularPlaces.madinah}</div>
                    </h3>
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
          <div className="col span_1_of_3">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={0}
            />
            <div className="widget widget_lastest">
              <h2 className="widget-title">
                <span>{lang.lang.pageHeaders.navigation}</span>
              </h2>
              <ul className="menu">
                {state.menus.map((data) => (
                  <li
                    className={
                      (data.links === location.pathname.substring(1) ||
                        data.links === activeSection) &&
                      data.id !== 6
                        ? 'menu-item menu-item-has-children current-menu-item'
                        : 'menu-item menu-item-has-children'
                    }
                    key={data.id}
                  >
                    <ScrollLink
                      style={{ cursor: 'pointer' }}
                      to={data?.links}
                      spy
                      onClick={() => {
                        if (props?.products) {
                          window.location.href = '/';
                        } // window.location.href = data.links;
                      }}
                    >
                      {data.name}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col span_1_of_3">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={0}
            />
            <div className="widget widget_lastest">
              <h2 className="widget-title">
                <span>{lang.lang.nav.contact}</span>
              </h2>
              <ul className="lastest-posts data-effect clearfix">
                <div
                  className="widget widget_text"
                  style={{
                    right: lang.language === 'english' ? '' : 0,
                  }}
                >
                  <span className="fa fa-map-marker" />
                  <span className="text">
                    {lang.lang.contact.address}
                    <br />
                    <span className="sl">{lang.lang.contact.subAddress}</span>
                  </span>
                </div>
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="widget widget_text">
                    <span className="fa fa-phone" />
                    <span className="text">
                      {/* {lang.lang.contact.callUs} : <br></br> */}
                      {lang.lang.contact.phoneNumber}
                      <br></br>
                      {lang.lang.contact.phoneNumber2}
                    </span>
                  </div>
                </li>
                <li className="clearfix">
                  <div className="elm-link">
                    <Link to="page-blog-single.html" className="icon-2" />
                  </div>
                  <div className="widget widget_text">
                    <span className=" font-size-14 fa fa-envelope" />
                    <span className="text">sales@Alalameiah.com</span>
                    <br></br>
                    <span className="text">info@Alalameiah.com</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const styles = {
  column: {
    display: 'flex',
  },
};

export default Footer;
