import { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';

const Slider = () => {
  const lang = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    console.log(isMobile);
  });
  const mainContent = () => {
    const bannerhome1 = [
      {
        id: 1,
        srcimg: 'assets/img/slider/newmain.jpg',
        text: lang.lang.slider.slider1,
        classnames:
          'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext:
          'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption',
      },
      {
        id: 2,
        srcimg: 'assets/img/slider/new1.jpg',
        text: lang.lang.slider.slider2,
        classnames:
          'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext:
          'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption',
      },
      {
        id: 3,
        srcimg: 'assets/img/slider/new2.jpg',
        text: lang.lang.slider.slider3,
        classnames:
          'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext:
          'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption',
      },
      {
        id: 4,
        srcimg: 'assets/img/slider/new3.jpg',
        text: lang.lang.slider.slider4,
        classnames:
          'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
        classtext:
          'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
        datax: "['left','left','left','center']",
        datahset: "['34','34','34','0']",
        datavset1: "['-134','-134','-134','-134']",
        datavset2: "['-63','-63','-63','-63']",
        datavset3: "['2','2','2','2']",
        datavset: "['106','106','106','106']",
        classbtn: 'tp-caption',
      },
    ];
    return (
      <>
        {bannerhome1.map((data) => (
          <li
            style={{ maxHeight: '680px', position: 'relative' }}
            data-transition="random"
            key={data.id}
          >
            <img
              src={data.srcimg}
              alt="altimage"
              data-bgposition="center center"
              data-no-retina
              style={{ width: '100%', height: '100%' }}
              // onLoad={() => handleImageLoad()}
            />
            <div
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1,
              }}
            ></div>
            <div
              className={data.classtext}
              data-x={data.datax}
              data-hoffset={data.datahset}
              data-y="['middle','middle','middle','middle']"
              data-voffset={data.datavset2}
              data-fontsize="['52','52','42','32']"
              data-lineheight="['65','65','45','35']"
              data-width="full"
              data-height="none"
              data-whitespace="normal"
              data-transform_idle="o:1;"
              data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
              data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
              data-mask_in="x:0px;y:[100%];"
              data-mask_out="x:inherit;y:inherit;"
              data-start={500}
              data-splitin="none"
              data-splitout="none"
              data-responsive_offset="on"
            >
              {data.text}
            </div>
          </li>
        ))}
      </>
    );
  };
  return <ul>{mainContent()}</ul>;
};

export default withRouter(Slider);
