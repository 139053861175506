export const english = {
  topBar: {
    follow: 'Follow Us:',
  },
  nav: {
    home: 'HOME',
    about: 'ABOUT US',
    products: 'PRODUCTS',
    contact: 'CONTACT US',
    MachineDescription: 'Machine Description',
    machineImages: 'Machine Images',
    MachineVideo: 'Machine Video',
    ViewAllProducts: 'View All Products',
    ExploreOurProducts: 'Explore Our Products',
  },
  slider: {
    slider1:
      'Valuable share between the customer and the company to keep the product or service running smoothly',
    slider2: 'The latest global technologies',
    slider3: 'Professionalism in providing services',
    slider4:
      'The ideal choice in the world of packaging machinery manufacturing and production of candy lines',
  },
  popularPlaces: {
    riyadh: 'Riyadh',
    riyadhDesc:
      'The Riyadh Province, also known as the Riyadh Region, is a region of Saudi Arabia, located in the geographic center of the country. It is the second-largest region by both area and population, behind the Eastern Province and Mecca Region respectively.',
    jeddah: 'Jeddah',
    firstmachinename: 'Candy Machine',
    secondmachinename: 'Half-automatic machine',
    thirdmachinename: 'Four pieces machine',
    fourthmachinename: 'Assembly Machine',
    fifthmachinename: 'Roll Machine',
    sixmachinename: 'Collecting Machine',
    sevenmachinename: 'Automatic Machine',
    firstmachinedesc:
      'Packaging all products in the form of a ball (bonbons, chewing gum, etc.) The machine contains four servo motors',
    secondmachinedesc:
      'Semi-automatic packaging machine for all cylindrical, rectangular or square shaped products The machine contains four main servo motors and two belts to feed the product',
    thirdmachinedesc:
      'All products are packaged in the form of a ball (bonbons - chewing gum - etc.), but it allows us to vary the colors of the product according to the customer’s need. The machine contains four main servo motors, with the possibility of increasing them according to the number of product colors and the customer’s need.',
    fourthmachinedesc:
      'Wrapping a certain number of rectangular fingers and assembling them in one row according to the customer’s desire The machine contains five main servo motors and two belts to feed the product',
    fifthmachinedesc:
      'Packaging cylindrical fingers in one or two pieces, according to the customer’s choice The machine contains five servo motors',
    sixmachinedesc:
      'Packaging in the form of more than one column, and each column contains a number of pieces according to the customer’s need The machine contains four servo motors',
    sevenmachinedesc:
      'Packaging all cylindrical or square shaped products, but without human intervention The machine contains four main servo motors and increases according to the automated method of the customer’s production lines.',
    jeddahDesc:
      "Jeddah is a city in the Hejaz region of Saudi Arabia and the country's commercial center. Established in the 6th century BC as a fishing village, Jeddah’s prominence grew in 647 when the Caliph Osman made it a major port for Indian Ocean trade routes, channelling goods to Mecca, and to serve Muslim travelers for Islamic pilgrimage.",
    madinah: 'Al Madinah Al Munawwarah',
    madinahDesc:
      'Medina, officially Al Madinah Al Munawwarah. It IS the fourth-most populous city in the country. Located at the core of the Medina Province in the western reaches of the country, while the rest is occupied by the Hejaz Mountains, empty valleys, agricultural spaces and older dormant volcanoes.',
    dammam: 'Dammam',
    dammamDesc:
      'Dammam is the fifth-most populous city in Saudi Arabia after Riyadh, Jeddah, Mecca, and Medina. It is the capital of the Eastern Province. The judicial and administrative bodies of the province, in addition to the administrative offices of other minor governmental departments functioning within the province, are located in the city.',
  },
  general: {
    firstService: 'Candy production lines',
    firstServiceDescription:
      'Production of candy, toffee and chewing gum lines',
    secondService: 'Horizontal packaging machines',
    secondServiceDescription:
      'Producing all the necessary packaging machines and feeders for all products according to the customer’s desire, taking into account the size of each type of length and width of the required product.',
    thirdService: 'After-sales service',
    thirdServiceDescription: 'Includes maintenance and supply of spare parts',
    fourthService: 'Manufacture of machines with automated feeding lines',
    fourthServiceDescription:
      'Manufacture of machines with automated feeding lines. To develop the machinery industry in line with the latest global technologies.',
    transport: 'Transportations',
    catering: 'Catering',
    pcr: 'PCR Test(s)',
    hotelServices:
      'Resevation and equipped tourists accomodation facilities licenced by Ministry of Tourism and qualified for quarantine, at all levels, five stars, four stars and three stars.',
    transportServices:
      'Providing the latest means of transportation from the airport to the shelter, in accordance with precautionary, requiements specified by the Public Health Authority with the presence of field supervisors.',
    cateringServices:
      'Suppling catering services in accordance with health requirements and standards, taking into account the list for each guest.',
    pcrServices:
      'Conducting PCR analyzes from accredited and licensed laboratories from the Saudi Ministry of Health.',
    nights: 'Nights',
    meals: 'Meals',
    oneWay: 'One Way',
    fromAirport: 'From Airport',
    visas: 'Tourist Visas',
    hotelReservation: 'Hotel Reservations of All Levels',
    transportations: 'Means of Transportation for Internal Transportation',
    hotelCatering: 'Catering Services for Hotels',
    institutionalQuarantine: 'Institutional Quarantine Services',
    copyright: 'Copyright',
    companyName: 'Al Alameiah',
    year: '@2024',
    a: 0,
    b: 1,
    c: 2,
    d: 3,
    e: 4,
    f: 5,
    g: 6,
    h: 7,
    i: 8,
    j: 9,
  },
  pageHeaders: {
    ourProducts: 'Our Products',
    quarantine: 'QUARANTINE SERVICES',
    ourHotels: 'OUR HOTELS',
    allotmentHotels: 'ALLOTMENT HOTELS',
    ourPartners: 'Our Partners',
    welcome: 'Welcome To Al Alameiah',
    overview: 'Overview',
    ourServices: 'Our Services',
    ourCompany: 'Our Company',
    ourVision: 'Our Vision',
    contactUs: 'Contact Us',
    leadership: 'LEADERSHIP',
    ourCities: 'OUR CITIES',
    navigation: 'NAVIGATION',
  },
  hotels: {
    taj: 'TAJ AL-RAQI',
    subTaj: 'TAJ AL-KHALIL PREVIOUSLY',
    hijra: 'AL-HIJRA AL-RAQI',
    subHijra: 'AFWAJ AL-TAWBAH 11 PREVIOUSLY',
    safwa: 'AL-SAFWA AL-RAQI',
    subSafwa: 'SAFWAT AL-TAJ PREVIOUSLY',
    malak: 'MALAK AL-RAQI',
    subMalak: 'MALAK AL-TAQWA PREVIOUSLY',
    kady: 'KADY AL-RAQI',
    subKady: 'ZOHOUR AL-TAJ PREVIOUSLY',
    azka: 'AZKA AL-SAFA',
    nesour: 'NESOUR AL-MOHAJEREEN',
    mathaer: 'MATHAER AL-JEWAR',
    fajr: 'FAJR AL-BADIE 2',
    royal: 'ROYAL MAJESTIC',
    bolman: 'BOLMAN ZAMZAM',
  },
  about: {
    companyDescTitle2: 'Company vision',
    companyDescTitle3: 'The Message',
    companyDesc:
      'The company was established in 1997 in the city of Homs - Syria. When it started, the company specialized in producing candy lines (bonbons) over a period of 5 years. In 2002, the company began producing horizontal packaging machines to cover all uses. It continued developing the machine, relying on the use of servo motors, replacing old mechanical systems due to their poor performance and slow movement. In 2012, the company continued its work in the Arab Republic of Egypt - 10th of Ramadan City and began manufacturing machines with automatic feeding lines. The company always seeks to develop the machinery industry in line with the latest global technologies.',
    companyDesc2:
      'We always strive for the name “International Company” to be a leading name and an ideal choice in the world of manufacturing packaging machines in Syria and Egypt by providing our services with high professionalism.',
    companyDesc3:
      'Directing attention towards implementing services to customers with the highest standards of quality and at the highest levels according to the budget we have allocated, taking into account the pre-determined time element.',
    visionDesc:
      "Serving the pilgrims from all over the world and its connection with the vision of our wise leadership. Our energies and capabilities to serve them, whose priority is to spare no effort in making every effort. Providing everything that meets the needs of God's guests and fulfills their aspirations.",
  },
  leaders: {
    director: 'Ahmed Khawaja',
    directorPos: 'Executive Director',
  },
  contact: {
    callUs: 'Call Us Now',
    support: 'Reach us By Email',
    supportPhone: 'Reach us By Phone',
    phoneNumber: '01555530306',
    phoneNumber2: '01277426891',
    address: '10th Of Ramadan City',
    subAddress: 'Our Location',
    availability: 'Give Us a Visit',
    mailUs: 'Contact Us Now',
    submit: 'Submit',
    YourEmail: 'Your Email',
    Phone: 'Phone',
    Name: 'Name',
    Subject: 'Subject',
    Message: 'Message',
  },
};
