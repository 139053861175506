import React from 'react';

const Partner = ({ lang }) => {
  let state = {
    partner: [
      {
        id: 1,
        img: 'assets/img/partners/logo1.jpg',
        imgcolor: 'assets/img/partners/partner-1.png',
      },
      {
        id: 2,
        img: 'assets/img/partners/logo2.jpg',
        imgcolor: 'assets/img/partners/partner-2.png',
      },
      {
        id: 3,
        img: 'assets/img/partners/logo3.jpg',
        imgcolor: 'assets/img/partners/partner-3.png',
      },
      {
        id: 4,
        img: 'assets/img/partners/logo4.jpg',
        imgcolor: 'assets/img/partners/partner-4.png',
      },
      {
        id: 6,
        img: 'assets/img/partners/logo5.jpg',
        imgcolor: 'assets/img/partners/partner-1.png',
      },
      {
        id: 7,
        img: 'assets/img/partners/logo6.jpg',
        imgcolor: 'assets/img/partners/partner-2.png',
      },
      {
        id: 8,
        img: 'assets/img/partners/logo7.jpg',
        imgcolor: 'assets/img/partners/partner-3.png',
      },
      {
        id: 9,
        img: 'assets/img/partners/logo8.jpg',
        imgcolor: 'assets/img/partners/partner-4.png',
      },
    ],
  };

  return (
    <div className="row-partner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="themesflat-carousel-box clearfix"
              data-gap={5}
              data-column={4}
              data-column2={3}
              data-column3={2}
              data-auto="true"
            >
              <div className="owl-carousel owl-theme">
                {state.partner.map((data) => (
                  <div
                    className="themesflat-partner style-1 align-center clearfix"
                    key={data.id}
                  >
                    <div className="partner-item">
                      <div
                        style={{ margin: '2%', borderRadius: '12px' }}
                        className="inner"
                      >
                        <div
                          // style={{ width: '180px', height: '120px' }}
                          className="thumb"
                        >
                          <img
                            style={{
                              width: '100%',
                              height: '100%',
                              resize: 'contain',
                            }}
                            src={data.img}
                            alt="altimage"
                            className="partner-default"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={66}
              data-mobile={60}
              data-smobile={60}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
