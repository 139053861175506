import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../LanguageContext';
import FormContact from './FormContact';

const IconBox = () => {
  const lang = useContext(LanguageContext);

  let state = {
    iconbox: [
      {
        id: 1,
        headding: lang.lang.contact.phoneNumber,
        headding2: lang.lang.contact.phoneNumber2,
        subheading: lang.lang.contact.supportPhone,
        morelink: lang.lang.contact.callUs,
        icon: 'icon-phone',
      },
      {
        id: 2,
        headding: lang.lang.contact.address,
        subheading: lang.lang.contact.subAddress,
        morelink: lang.lang.contact.availability,
        link: "https://www.google.com/maps/place/30%C2%B016'49.9%22N+31%C2%B043'58.2%22E/@30.2805138,31.7302494,17z/data=!3m1!4b1!4m4!3m3!8m2!3d30.2805138!4d31.7328243?entry=ttu",
        icon: 'icon-map',
      },
      {
        id: 3,
        headding: 'sales@Alalameiah.com',
        headding2: 'info@Alalameiah.com',
        subheading: lang.lang.contact.support,
        morelink: lang.lang.contact.mailUs,
        icon: 'icon-envelope',
      },
    ],
  };
  return (
    <>
      <div className="row gutter-16">
        {state.iconbox.map((data) => (
          <div className="col-md-4" key={data.id}>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={0}
              data-mobile={0}
              data-smobile={35}
            />
            <div className="themesflat-icon-box icon-top align-center accent-color style-3 bg-light-snow clearfix">
              <div className="icon-wrap">
                <i className={data.icon} />
              </div>
              <div className="text-wrap">
                <h5 className="heading">
                  <a>{data.headding}</a>
                  <br></br>
                  <a>{data.headding2}</a>
                </h5>
                <p className="sub-heading">{data.subheading}</p>
                <span className="sub-heading">
                  <a href={data.link} target="_blank" rel="noopener noreferrer">
                    {data.morelink}
                  </a>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>

      <FormContact />
    </>
  );
};

const styles = {
  whatsappfloat: {
    position: 'fixed',
    width: 60,
    height: 60,
    bottom: 10,
    right: 70,
    backgroundColor: '#25d366',
    color: '#FFF',
    borderRadius: 50,
    textAlign: 'center',
    fontSize: 30,
    boxShadow: '2px 2px 3px #999',
    zIndex: 100,
  },
  whatsappicon: {
    marginTop: 16,
  },
  phonefloat: {
    position: 'fixed',
    width: 60,
    height: 60,
    bottom: 10,
    right: 140,
    backgroundColor: '#A9A9A9',
    color: '#FFF',
    borderRadius: 50,
    textAlign: 'center',
    fontSize: 30,
    boxShadow: '2px 2px 3px #999',
    zIndex: 100,
  },
  phoneicon: {
    marginTop: 16,
  },
  mailfloat: {
    position: 'fixed',
    width: 60,
    height: 60,
    bottom: 10,
    right: 210,
    backgroundColor: '#0096FF',
    color: '#FFF',
    borderRadius: 50,
    textAlign: 'center',
    fontSize: 30,
    boxShadow: '2px 2px 3px #999',
    zIndex: 100,
  },
  mailicon: {
    marginTop: 16,
  },
};

export default IconBox;
