import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { LanguageConsumer, LanguageContext } from '../../LanguageContext';
import BannerTitle from '../layouts/about/banner/BannerTitle';
import HotelCard from '../layouts/general/HotelCard';
import { Footer, BottomBar, TopBar, Header } from '../layouts/general/index';
import styles from './styles.css';
import { TitleHeading } from '../layouts/home';
import { Modal } from '@mui/material';

const Products = () => {
  const activeUrl = useLocation();
  const [isMaximized, setIsMaximized] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');
  const [lightboxAltImage, setLightboxAltImage] = useState('');

  const openLightbox = () => {
    setLightboxImage(data?.srcimg);
    setLightboxAltImage('alt');
  };

  const closeLightbox = () => {
    setLightboxImage('');
    setLightboxAltImage('');
  };

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };
  const parseQueryString = (queryString) => {
    return JSON.parse(decodeURIComponent(queryString));
  };
  const data = parseQueryString(
    new URLSearchParams(activeUrl.search).get('data')
  );

  // const headerName = activeUrl.state?.headerName;

  const lang = useContext(LanguageContext);

  let state = {
    headers: [
      {
        id: 1,
        logoweb: 'assets/img/logo-small.png',
        names: lang.lang.nav.products,
      },
    ],
    banners: [
      {
        id: 1,
        links: '/hotels',
        titlelink: '',
        border: '',
        name: lang.lang.nav.products,
        heading: lang.lang.nav.products,
      },
    ],
    headingcontact: [
      {
        id: 1,
        classnames: 'heading',
        // title: lang.lang.nav.products,
        title: data?.headerName,
      },
    ],
  };
  const [isMobile, setIsMobile] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize(); // Call on mount
    window.addEventListener('resize', handleResize); // Call on resize
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up
    };
  }, []);
  const openImageModal = (imageSrc) => {
    // console.log('Opening modal...');
    setSelectedImage(imageSrc);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    // console.log('Closing modal...');
    setSelectedImage('');
    setIsImageModalOpen(false);
  };
  return (
    <LanguageConsumer>
      {({ language }) => (
        <>
          <div
            className="header-fixed page no-sidebar header-style-3 topbar-style-3 site-header-absolute menu-has-search"
            id="products"
          >
            {/* <div id="wrapper" className="animsition"> */}
            <div id="page" className="clearfix">
              <div id="site-header-wrap">
                <div>
                  {state.headers.map((data, idx) => (
                    <Header type={'product'} data={data} key={idx} products />
                  ))}
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '500px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignSelf: 'center',
                  }}
                >
                  <img
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex:',
                      alignSelf: 'center',
                    }}
                    src="assets/img/slider/newmain.jpg"
                    alt="altimage"
                    data-bgposition="center center"
                    data-no-retina
                  />
                  <div
                    style={{
                      backgroundColor: 'rgba(0, 0, 0, 0.7)',
                      position: 'absolute',
                      width: '100%',
                      height: '500px',
                      zIndex: 1,
                    }}
                  ></div>
                  <div
                    className={
                      'tp-caption tp-resizeme text-white font-heading font-weight-800 text-right'
                    }
                    style={{ fontSize: '35px', fontWeight: 'bold' }}
                  >
                    {data?.headerName}
                  </div>
                </div>
                {/* {state.banners.map((data) => (
                <BannerTitle key={data.id} data={data} />
              ))} */}
                <div
                  className="themesflat-headings style-1 clearfix"
                  style={{ marginTop: '5%' }}
                >
                  {state.headingcontact.map((data) => (
                    <TitleHeading data={data} key={data.id} />
                  ))}
                </div>

                <section className="hero-section" id="products">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-12">
                        <nav
                          id="navbar-example3"
                          className="h-100 flex-column align-items-stretch"
                        >
                          <nav className="nav nav-pills flex-column">
                            <a
                              style={{
                                fontSize: '18px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                              className={`nav-link smoothscroll ${
                                activeUrl.hash === '#item-1' ? 'active' : ''
                              }`}
                              href="#item-1"
                            >
                              {lang.lang.nav.MachineDescription}
                            </a>

                            <a
                              style={{
                                fontSize: '18px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                              className={`nav-link smoothscroll ${
                                activeUrl.hash === '#item-2' ? 'active' : ''
                              }`}
                              href="#item-2"
                            >
                              {lang.lang.nav.machineImages}
                            </a>

                            <a
                              style={{
                                fontSize: '18px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                              className={`nav-link smoothscroll ${
                                activeUrl.hash === '#item-3' ? 'active' : ''
                              }`}
                              href="#item-3"
                            >
                              {lang.lang.nav.MachineVideo}
                            </a>

                            {/* <a
                            className={`nav-link smoothscroll ${
                              activeUrl.hash === '#item-4' ? 'active' : ''
                            }`}
                            href="#item-4"
                          >
                            Chapter 3: <strong>Delegate</strong>
                          </a> */}

                            {/* <a
                            className={`nav-link smoothscroll ${
                              activeUrl.hash === '#item-5' ? 'active' : ''
                            }`}
                            href="#item-5"
                          >
                            Technical Specifications
                          </a> */}
                          </nav>
                        </nav>
                      </div>

                      <div className="col-lg-8 col-12">
                        <div
                          data-bs-spy="scroll"
                          data-bs-target="#navbar-example3"
                          data-bs-smooth-scroll="true"
                          className="scrollspy-example-2"
                          tabIndex="0"
                        >
                          <div
                            className="scrollspy-example-item"
                            id="item-1"
                            style={{ paddingTop: '15%' }}
                          >
                            <h5
                              style={{
                                fontSize: '28px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                            >
                              {lang.lang.nav.MachineDescription}
                            </h5>

                            <p
                              style={{
                                fontSize: '20px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                            >
                              {data?.machinedesc}
                            </p>
                          </div>

                          <div
                            className="scrollspy-example-item"
                            id="item-2"
                            style={{ paddingTop: '15%' }}
                          >
                            <h5
                              style={{
                                fontSize: '28px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                            >
                              {lang.lang.nav.machineImages}
                            </h5>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  width: '100%',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {/* <div>
                                  <div className="gallery">
                                    <button
                                      type="button"
                                      className="gallery__item"
                                      onClick={() => openLightbox(data?.srcimg)}
                                    >
                                      <img
                                        className="gallery__image"
                                        src={data?.srcimg}
                                        alt={'altimage'}
                                      />
                                    </button>
                                  </div>

                                  {lightboxImage && (
                                    <div className="lightbox">
                                      <button
                                        type="button"
                                        className="lightbox__close-button"
                                        onClick={closeLightbox}
                                      >
                                        &times;
                                      </button>
                                      <img
                                        className="lightbox__image"
                                        src={lightboxImage}
                                        alt="altimage"
                                      />
                                      <button
                                        type="button"
                                        className="lightbox__bg"
                                        onClick={closeLightbox}
                                      />
                                    </div>
                                  )}
                                </div> */}
                                <div
                                  style={{
                                    borderRadius: 15,
                                    cursor: 'pointer',
                                    width: isMobile ? '250px' : '400px',
                                    height: isMobile ? '170px' : '320px',
                                  }}
                                  className="content"
                                  onClick={() => openImageModal(data?.srcimg)}
                                >
                                  <div
                                    style={{
                                      padding: '10px',
                                      backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                      position: 'absolute',
                                      width: '100%',
                                      height: '100%',
                                    }}
                                  ></div>
                                  <img
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                      display: 'flex:',
                                      alignSelf: 'center',
                                    }}
                                    src={data?.srcimg}
                                    alt="altimage"
                                    data-bgposition="center center"
                                    data-no-retina
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className="scrollspy-example-item"
                            id="item-3"
                            style={{ paddingTop: '15%' }}
                          >
                            <h5
                              style={{
                                fontSize: '28px',
                                textAlign:
                                  lang.language === 'english'
                                    ? 'left'
                                    : 'right',
                              }}
                            >
                              {lang.lang.nav.MachineVideo}
                            </h5>

                            <div
                              style={{
                                position: 'relative',
                                paddingBottom: '56.25%',
                                paddingTop: '30px',
                                height: 0,
                                overflow: 'hidden',
                              }}
                            >
                              <iframe
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  width: '100%',
                                  height: '100%',
                                  borderRadius: '12px',
                                }}
                                width="560"
                                height="315"
                                src={data?.srcVideo}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          </div>

                          {/* <div
                          className="scrollspy-example-item"
                          id="item-5"
                          style={{ paddingTop: '15%' }}
                        >
                          <h5>Technical Specifications</h5>

                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>

                          <p>
                            You are not allowed to redistribute this template
                            ZIP file on any other template collection website.
                            Please contact TemplateMo for more information.
                          </p>

                          <p>
                            <strong>What is Free CSS Templates?</strong> Free
                            CSS Templates are a variety of ready-made web pages
                            designed for different kinds of websites.
                          </p>

                          <blockquote className="blockquote">
                            Lorem Ipsum dolor sit amet, consectetur adipsicing
                            kengan omeg kohm tokito
                          </blockquote>

                          <p>
                            You may browse TemplateMo website for more CSS
                            templates. Thank you for visiting our website.
                          </p>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                {/* </div> */}
              </div>
              {/* {state.headers.map((data, idx) => (
              <Footer data={data} key={idx} />
            ))} */}
              {/* {state.headers.map((data, idx) => (
              <BottomBar data={data} key={idx} lang={lang} />
            ))} */}
            </div>
            {/* {!isMobile && ( */}
            <Modal
              open={isImageModalOpen}
              onClose={closeImageModal}
              aria-labelledby="image-modal"
              aria-describedby="enlarged-image"
              style={{
                // width: '100%',
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  maxWidth: '80vw',
                  // maxHeight: '80vh',
                  // padding: '10px',
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    alignSelf: 'flex-end',
                    borderRadius: '50px',
                    marginBottom: '5px',
                  }}
                  onClick={closeImageModal}
                >
                  Close
                </button>
                <div
                  style={{
                    maxWidth: '80vw',
                    height: '80vh',
                    backgroundColor: '#fff',
                    borderRadius: '12px',
                    // backgroundColor: 'red',
                  }}
                >
                  <img
                    src={selectedImage}
                    alt="Large Image"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '24px',
                      display: 'block',
                      margin: '0 auto',
                    }}
                  />
                </div>
              </div>
            </Modal>
            {/* )} */}
          </div>
        </>
      )}
    </LanguageConsumer>
  );
};

export default Products;
