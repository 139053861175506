import React from 'react';
import TitleHeading from './TitleHeading';
import IconBox from './IconBox';

const About = ({ lang }) => {
  let state = {
    tab: [
      {
        id: 1,
        title: lang.lang.pageHeaders.ourCompany,
        text: lang.lang.about.companyDesc,
        text2: lang.lang.about.companyDesc2,
        text3: lang.lang.about.companyDesc3,
        item: [],
      },
      {
        id: 2,
        title: lang.lang.pageHeaders.ourVision,
        text: lang.lang.about.visionDesc,
        item: [],
      },
    ],
  };
  return (
    <div className="row-iconbox bg-row-2" style={{ paddingBottom: 30 }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="themesflat-spacer clearfix"
              data-desktop={60}
              data-mobile={60}
              data-smobile={60}
            />
            <TitleHeading data={{ title: lang.lang.pageHeaders.welcome }} />
            <p
              style={{ textAlign: 'center', fontSize: 18 }}
              className="heading margin-top-30"
            >
              {lang.lang.about.companyDesc}
            </p>
            <h3
              style={{ textAlign: 'center' }}
              className="heading margin-top-10"
            >
              {lang.lang.about.companyDescTitle2}
            </h3>
            <p
              style={{ textAlign: 'center', fontSize: 18 }}
              className="heading margin-top-10"
            >
              {lang.lang.about.companyDesc2}
            </p>
            <h3
              style={{ textAlign: 'center' }}
              className="heading margin-top-10"
            >
              {lang.lang.about.companyDescTitle3}
            </h3>
            <p
              style={{ textAlign: 'center', fontSize: 18 }}
              className="heading margin-top-10"
            >
              {lang.lang.about.companyDesc3}
            </p>

            <div className="sep has-width w100 accent-bg margin-top-11 clearfix" />
            <div
              className="themesflat-spacer clearfix"
              data-desktop={25}
              data-mobile={35}
              data-smobile={35}
            />
            <div className="row">
              <div className="col-md-12">
                <div
                  className="themesflat-spacer clearfix"
                  data-desktop={80}
                  data-mobile={60}
                  data-smobile={60}
                />
              </div>
            </div>
            <h2 className="heading text-center margin-bottom-30">
              {lang.lang.pageHeaders.ourServices}
            </h2>
            <div
              className="themesflat-spacer clearfix"
              data-desktop={26}
              data-mobile={35}
              data-smobile={35}
            />
            <div className="row-iconbox bg-row-2">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <IconBox />
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="themesflat-spacer clearfix"
                          data-desktop={80}
                          data-mobile={60}
                          data-smobile={60}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <IconBox /> */}
            <div
              className="themesflat-spacer clearfix"
              data-desktop={26}
              data-mobile={35}
              data-smobile={35}
            />
          </div>
        </div>
        {/* <h2 className="heading text-center margin-top-30">
          {lang.lang.pageHeaders.overview}
        </h2> */}
        <div
          className="themesflat-spacer clearfix"
          data-desktop={38}
          data-mobile={35}
          data-smobile={35}
        />
        {/* {state.tab.map((data) => (
          <div
            style={{ textAlign: 'center' }}
            className="tab-content"
            key={data.id}
          >
            <p style={{ fontSize: 20, fontWeight: 'bold' }}>{data.title}</p>
            <div className="item-content">
              <p>{data.text}</p>
              {data.item.map((index) => (
                <div
                  className="themesflat-list has-icon style-1 icon-left ofset-padding-32 clearfix"
                  key={index.idx}
                >
                  <div className="inner">
                    <span className="item">
                      <span
                        className="icon"
                        style={{
                          right: lang.language === 'english' ? '' : 0,
                        }}
                      >
                        <i className="fa fa-check-square" />
                      </span>
                      <span
                        className="text"
                        style={{
                          paddingRight: 20,
                        }}
                      >
                        {index.content}
                      </span>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))} */}
      </div>
    </div>
  );
};

export default About;
